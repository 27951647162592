export const hero = [
  {
    "id": 1,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/12/descarga-15.jpg",
    "category": "ECONOMÍA",
    "title": "Comienza el pago de plus complementario a municipales",
    "time": "11 Dic - 2024",
    "desc": [
      {
        "para1": "A través de las redes sociales, el intendente Eduardo Tassano dio a conocer el cronograma de pago del plus complementario a municipales. El mismo iniciará este viernes, y se extenderá hasta el martes inclusive."
      },
      {
        "para2": "Los trabajadores de planta, contrato y los agentes Neike bancarizados tendrán depositado el pago del plus en sus respectivas cuentas bancarias este viernes, dinero al que podrán acceder a través de los cajeros automáticos habilitados."
      },
      {
        "para3": "Por su parte, aquellos trabajadores Neike que no están bancarizados deberán dirigirse a la Caja Municipal de Préstamos (CMP), en su sede central, ubicada en Brasil 1269. Los pagos se realizan de lunes a viernes, de 7:30 a 12:30 y de 16 a 20."
      }
    ],
    "details": [
      {
        "title": "Cronograma de Pago"
      },
      {
        "para1": "El viernes podrán cobrar aquellos trabajadores cuyos DNI terminen en 0, 1 y 2. Tras el fin de semana, el lunes se abonará a los agentes con DNI finalizados en 3, 4, 5 y 6; mientras que el martes 17 se completará con quienes tengan documentos terminados en 7, 8 y 9."
      },
      {
        "title": "Neike Bancarizados sin Tarjeta de Débito"
      },
      {
        "para1": "Los Neike bancarizados que no posean tarjeta de débito deberán cobrar por ventanilla del Banco de Corrientes (sucursal avenida Teniente Ibáñez 1826), siguiendo el mismo cronograma, desde el viernes 13 hasta el martes 17."
      }
    ]
  },
{
  "id": 2,
  "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/12/descarga-5-1.jpg",
  "category": "POLICIALES",
  "title": "Golpe al contrabando: decomisaron una tonelada de marihuana en Corrientes",
  "time": "11 Dic - 2024",
  "desc": [
    {
      "para1": "Gendarmería Nacional decomisó una tonelada de marihuana que fue abandonada por el conductor de un furgón en Corrientes."
    },
    {
      "para2": "El episodio ocurrió anoche, cuando efectivos de la Sección Seguridad Vial “Paso de la Patria” del Escuadrón 48 con apoyo del personal de la Unidad de Inteligencia Criminal realizaban controles viales sobre la Ruta Nacional N° 12."
    },
    {
      "para3": "En la zona procedieron a efectuar las señales de tránsito a un furgón para que el conductor disminuyera y detuviera la marcha, pero el mismo hizo caso omiso y llevó a cabo una maniobra brusca atropellando el dispositivo y posteriormente embistió un vehículo de la institución."
    }
  ],
  "details": [
    {
      "title": "Operativo y Hallazgo"
    },
    {
      "para1": "Ante tal situación, los uniformados iniciaron un seguimiento y, a la altura del kilómetro 1.048, hallaron el rodado abandonado con bultos de grandes dimensiones en su interior."
    },
    {
      "para1": "El personal de la Fuerza solicitó autorización a la Fiscalía Federal N° 1 de Corrientes para trasladar el operativo hacia el asiento de la Unidad, donde contabilizaron 45 bultos con 1.502 “ladrillos” de cannabis sativa, totalizando 1.080 kilos 843 gramos."
    },
    {
      "para1": "La Fiscalía interviniente dispuso el labrado de las actuaciones y la incautación de la droga y el vehículo, en infracción a la Ley 23.737."
    }
  ]
},
{
  "id": 3,
  "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/12/02-FOTO-24-PARA-WEB.jpg",
  "category": "POLÍTICA",
  "title": "El valdesismo se organiza para el acto de este sábado",
  "time": "11 Dic - 2024",
  "desc": [
    {
      "para1": "Como encuentro preparatorio para afinar detalles de lo que será el acto del sábado en el club San Martín, último cónclave antes de la interna del domingo 22, referentes del radicalismo afín a Gustavo Valdés se reunieron este martes 10 en la Sociedad Española de la ciudad de Corrientes."
    },
    {
      "para2": "Durante la reunión, se trataron los pormenores destinados a exhibir el respaldo interno ante la contienda con el sector liderado por la ex senadora Alejandra Seward, evidenciando así el músculo orgánico del valdesismo."
    },
    {
      "para3": "El gobernador Valdés estuvo acompañado por el intendente Eduardo Tassano, el ministro de Obras Públicas Claudio Polich, y los senadores Noel Breard, Sergio Flinta y Diógenes González, entre otros referentes de la UCR provincial."
    }
  ],
  "details": [
    {
      "title": "Contexto Político"
    },
    {
      "para1": "La Justicia Federal analiza un recurso presentado por Ricardo Colombi en contra de la intervención radical dispuesta por la mesa nacional, encabezada por Martín Lousteau, así como también la fecha de la interna del 22 de diciembre. A pesar de las presentaciones, se mantienen los requisitos orgánicos para la contienda."
    },
    {
      "title": "Agenda del Gobernador"
    },
    {
      "para1": "Mientras se aguardan definiciones, Valdés continuará su agenda oficial: este miércoles, visitará Monte Caseros a las 15 para inaugurar la Comisaría 3a; luego, a las 17, arribará a Mocoretá para habilitar una Estación Transformadora y obras complementarias en el Parque Industrial, fortaleciendo así la infraestructura local."
    }
  ]
}
,
{
  "id": 4,
  "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/12/03-FOTO-30-Gentileza-3.jpg",
  "category": "ECONOMÍA",
  "title": "El Poder Judicial se adelanta con un aumento salarial del 10 por ciento",
  "time": "11 Dic - 2024",
  "desc": [
    {
      "para1": "En un movimiento inesperado, el Poder Judicial de la provincia anunció un incremento salarial del 10 por ciento a partir del mes en curso, diferenciándose del resto de la administración pública."
    },
    {
      "para2": "La medida, instrumentada mediante el Acuerdo Extraordinario N°12/24, beneficia a todo el personal: planta administrativa, técnico-profesional, maestranza, servicios, magistrados y funcionarios. Esta corrección se reflejará en los haberes de diciembre, marcando distancias con otras áreas provinciales."
    },
    {
      "para3": "La decisión, refrendada por el presidente del STJ, Luis Eduardo Rey Vázquez, y los ministros Eduardo Panseri, Fernando Augusto Niz, Guillermo Horacio Semhan y Alejandro Alberto Chaín, contó con la presencia del fiscal general, César Pedro Sotelo, y del secretario administrativo subrogante, Edgar Abraham Parras."
    }
  ],
  "details": [
    {
      "title": "Disparidades Salariales en la Provincia"
    },
    {
      "para1": "Aunque los jueces correntinos perciben menos que sus pares federales, siguen siendo los mejores pagos dentro del ámbito provincial. Tras este aumento, el magistrado con mayor remuneración superará con creces los sueldos del Gobernador, el Vicegobernador, los ministros provinciales e incluso duplicará los haberes de los legisladores."
    },
    {
      "para1": "En el plano municipal, también se reflejan estas brechas: el Intendente de Santo Tomé obtiene un salario cercano al de un alto funcionario judicial, evidenciando la desigualdad salarial entre las distintas estructuras del Estado."
    },
    {
      "title": "Enganche, Debate Abierto"
    },
    {
      "para1": "La polémica figura del «enganche» salarial, que garantiza incrementos automáticos a los magistrados en función de los ajustes al personal judicial, vuelve a colocarse en el centro del debate. Mientras algunos defienden la previsibilidad y estabilidad que aporta este sistema, otros señalan la injusticia de que un solo Poder del Estado pueda asegurarse actualizaciones regulares."
    },
    {
      "para1": "En un contexto en el que muchas jurisdicciones enfrentan restricciones presupuestarias, el aumento del Poder Judicial refuerza su posición privilegiada en materia de haberes."
    },
    {
      "title": "Perspectivas para 2025"
    },
    {
      "para1": "De cara al próximo año, el presidente del STJ, Eduardo Rey Vázquez, indicó que no se prevén nuevas subas hasta marzo de 2025, y ante la posibilidad de aplicar otro 10 por ciento, afirmó: «Llegado el momento, veremos.»"
    },
    {
      "para1": "De esta manera, las discusiones sobre equidad salarial y ajustes en el sector público se proyectan hacia el próximo año, manteniendo el foco sobre la distribución de recursos y la situación de los diferentes poderes y niveles de gobierno."
    }
  ]
}
,
]
export const world = [
  {
    id: 5,
    cover: "https://www.lanacion.com.ar/resizer/v2/la-devaluacion-trajo-fuertes-costos-que-el-equipo-NTIFKCFGXRCGPNQBAVZKGCEZ3Y.JPG?auth=98785e2b145e8eef125b3919f7e51fb1a3c1486a797cf16f150738ed9f1b12cf&width=880&height=586&quality=70&smart=false",
    category: "ECONOMIA",
    title: "Repartir hasta lo ajeno para pagar la elevada factura de la devaluación oficial",
    authorName: "Salman",
    time: "5 Jun - 2022",
    desc: [
      {
        para1: "You audience. Least, the recently his repeat the this avarice for the have and was on would before the concise bedding were hall politely name be regretting have of even five of it the his are there again. Word seven designer far lady problem will have work with you to fully understand your business to achieve.",
      },
      {
        para2: "We work with clients big and small across a range of sectors and we utilize all forms of media to get your name out there in a way that’s right for you. We believe that analysis of your company and your customers is key in responding effectively to your promotional needs and we will work with you.",
      },
      {
        para3: "We have a number of different teams within our agency that specialise in different areas of business so you can be sure that you won’t receive a generic service and although we can’t boast years and years of service we can ensure you that is a good thing in this industry.",
      },
    ],
    details: [
      {
        title: "Starting a new company is easy",
      },
      { para1: "Our teams are up to date with the latest technologies, media trends and are keen to prove themselves in this industry and that’s what you want from an advertising agency, not someone who is relying on the same way of doing things that worked 10 years, 5 years or even a year ago." },
      {
        quote: "Scarfs, still not this no with explains it me and option on the any options roasted when I and state can that an don't subjective of has his take on and in from royal everything took raising our have behind success you the mechanic.",
      },
      {
        para2: "And, higher by agency; In from their in and we spirit, through merely small him sounded a all now, with that put gift white highly geared that was left back as of or logged important. A over have the large try understanding the believe. Perfected been viewer. Shreds early willingly safely what passion the.",
      },
      {
        para3: "In an ideal world this website wouldn’t exist, a client would acknowledge the importance of having web copy before the design starts. Needless to say it’s very important, content is king and people are beginning to understand that. However, back over in reality some project schedules and budgets don’t allow for web copy to be written before the design phase, this is sad but true.",
      },
    ],
  },
  {
    id: 6,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Renewablee91 ener553gy dead industry waits for Policya ",
    authorName: "Salmana",
    time: "5 Jun - 2022",
    desct: "If rather, him the would treble-range wild any long of policy waved preparations disguised first initial that commitment had my in for spirits gradual solitary I for his absolutely with it and I to unable follow the to as every it the control and a of thinks the what's help days, with and paint, series at window and could we've offer, hard with king either written dry be are of princesses. We’re here to help, we’ve written examples of web copy for over 40 industries for you to use at concept phase of your projects to bring a little life and realism to your designs and help you think about who and what you are designing for. We want clients and designers alike to think about their design and how it will work with the web copy, we want you think about how numbers, symbols and bullet points will look. Sure, it's easier to just run down the forms and fill the fields with garbage in order to plow through them quickly. But that's not real. That's not what your customers are going to do. Is it really smart to take a shortcut when customers are forced to take the long road? When you just enter fake copy in rapid-fire fashion, you don't know what it really feels like to fill out that form. Do as your customers do and you'll understand them better. When you understand them better, and feel what they feel, you'll build a better interface.",
  },
  {
    id: 7,
    cover: "../images/world/world3.jpg",
    category: "",
    title: "Mount Etna erupts fashion in nice12 looking pictures ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 8,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "China asks Canada to add their full plan ",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
  {
    id: 9,
    cover: "../images/world/world2.jpg",
    category: "",
    title: "Serena secures comeback win against Ivanovic",
    authorName: "Salman",
    time: "5 Jun - 2022",
  },
]
export const popular = [
  {
    "id": 10,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/12/02-FOTO-20-Gentileza-1.jpg",
    "category": "ECONOMÍA",
    "title": "Sectores productivos, atentos a nuevas estrategias para el fomento industrial",
    "date": "11 Dic - 2024",
    "desc": [
      {
        "para1": "Empresarios y referentes de la economía regional observan con preocupación las nuevas estrategias que se implementarán para sustituir o complementar las iniciativas eliminadas por el Gabinete presidencial, entre ellas la reciente derogación del Programa Nacional para el Desarrollo de los Parques Industriales."
      },
      {
        "para2": "La supresión del Programa Nacional para el Desarrollo de los Parques Industriales, decretada por el Gobierno de Javier Milei, encendió alarmas en el ámbito productivo, dado que era una herramienta clave para fomentar la radicación de empresas, desarrollar infraestructura y generar empleo local."
      },
      {
        "para3": "Su eliminación deja un vacío crítico en la política de promoción industrial y genera incertidumbre sobre el impacto en la competitividad de las economías regionales."
      }
    ],
    "details": [
      {
        "title": "Expectativas de Políticas Inclusivas"
      },
      {
        "para1": "Frente a este escenario, los sectores productivos exigen respuestas claras del Ejecutivo nacional. Empresarios coinciden en que es indispensable que las políticas futuras sean inclusivas y favorezcan tanto a grandes conglomerados como a Pymes."
      },
      {
        "title": "Urgencia de Diálogo y Medidas Concretas"
      },
      {
        "para1": "Los actores productivos reclaman un diálogo constructivo con el Gobierno para establecer prioridades que garanticen el desarrollo económico sostenible. Entre las medidas más urgentes destacan incentivos fiscales, mejora en la infraestructura logística y el impulso a la innovación tecnológica."
      },
      {
        "title": "Replanteo de las Políticas Industriales"
      },
      {
        "para1": "La eliminación del Programa Nacional para el Desarrollo de los Parques Industriales, una política creada en 2010 y ampliada en 2020, refleja el apartamiento estatal en la promoción industrial. El Gobierno argumenta la necesidad de optimizar recursos y rediseñar las estrategias productivas."
      },
      {
        "title": "Justificación"
      },
      {
        "para1": "El Decreto 1.077/2024 elimina los decretos previos que daban sustento al programa, justificando el cambio en los desequilibrios económicos y sociales acumulados. Se busca priorizar nuevas estrategias para sostener la competitividad de los sectores productivos."
      },
      {
        "title": "Impacto en el Ordenamiento"
      },
      {
        "para1": "La derogación implica la pérdida de una herramienta clave para el ordenamiento territorial, el asociativismo industrial y el desarrollo sustentable e inclusivo. Sin estos financiamientos, se dificulta la radicación o ampliación de empresas en Parques Industriales, impactando en la estrategia de desarrollo industrial del país."
      }
    ]
  }
  ,
  {
    "id": 11,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/12/469888857_600270202552297_7261389298485553999_n.jpg",
    "category": "POLICIALES",
    "title": "Allanamientos y personas demoradas tras encontrar drogas en un barrio capitalino",
    "date": "10 Dic - 2024",
    "desc": [
      {
        "para1": "Luego de una denuncia por robo en un centro de cultivo de cannabis sativa lindante a la escuela Eragia, la Policía efectuó allanamientos este martes en un domicilio sobre la avenida Raúl Alfonsín al 6400, en el Barrio Cremonte."
      },
      {
        "para2": "Si bien no se encontraron elementos relacionados al hecho denunciado, se incautaron varias bochas de cocaína, una balanza, dinero en efectivo y teléfonos celulares. Además, se demoró a una mujer menor de edad."
      },
      {
        "para3": "Del operativo participaron efectivos de la Comisaría 16ª, en colaboración con la Unidad Fiscal interviniente."
      }
    ],
    "details": [
      {
        "title": "Elementos Secuestrados"
      },
      {
        "para1": "En el allanamiento se hallaron 2 envoltorios con sustancia blanca compacta (bochones) y 24 envoltorios con sustancia tipo “bochita”. También se incautaron 50.120 pesos, 5 teléfonos celulares, 1 balanza con capacidad hasta 40 kg y recortes de bolsa de polietileno."
      },
      {
        "para1": "La Dirección General de Drogas Peligrosas y Crimen Organizado realizó el test orientativo sobre la sustancia, que dio positivo para cocaína, con un peso total de 90 gramos."
      },
      {
        "title": "Disposición Judicial"
      },
      {
        "para1": "La menor demorada, junto con la sustancia y los elementos incautados, fue puesta a disposición de la autoridad judicial correspondiente, profundizando así la investigación en torno a la procedencia del estupefaciente y las posibles vinculaciones con el delito denunciado."
      }
    ]
  }
  ,
  {
    "id": 12,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/7a08e1bf-14dd-4490-8aeb-731ba7902175.jpg",
    "category": "SEGURIDAD",
    "title": "Trágica e insólita muerte en el Parque Camba Cuá: era estudiante de periodismo",
    "date": "23 Nov - 2024",
    "desc": [
      {
        "para1": "Se trataba de Juan Pablo Ramos (21), oriundo de la provincia de Formosa, estudiante de periodismo quien en la madrugada de este sábado, perdió la vida en el parque Camba Cuá de la ciudad de Corrientes. La muerte lo sorprendió cuando ingresó a la casilla de la estación de bombeo ubicada junto al playón central y falleció ahogado."
      },
      {
        "para2": "Tras el infortunado episodio, Bomberos Voluntarios y policías de Corrientes, durante la mañana, llevaron adelante el procedimiento para rescatar el cuerpo del estudiante. Varios patrulleros, un autobomba y una ambulancia se encontraban en el lugar este mediodía."
      },
      {
        "para3": "El comisario Miguel Ángel Leguizamón, jefe de la Policía de Corrientes, relató a la prensa que “pasadas las 2.30, (el joven) se dirige hacia la casilla que funcionaba aparentemente como una estación de bombeo, cuando se inundaba (el playón) que tiene el parque”, y no salió."
      }
    ],
    "details": [
      {
        "title": "Detalles del Accidente"
      },
      {
        "para1": "En la continuidad de su relato, el Jefe policial dijo que pasados unos minutos al no regresar Ramos junto a sus amigos, “la pareja, se acerca a mirar y como no lo ven, ellos van al casino a ver si tomó otro rumbo, pero no lo encuentran, y entonces se dirigen a la Comisaría Primera, y hacen la denuncia”."
      },
      {
        "title": "Investigación del Accidente"
      },
      {
        "para1": "Leguizamón reveló que tras el aviso a las autoridades se analizaron todas las cámaras del Sistema 911, y corroboraron que “el chico estaba aquí en el parque”, además «una de las cámaras del Casino que enfoca a la casilla, lo muestra a las 2.38, cuando ingresa y no sale más. Luego, pasados unos 5 minutos, la pareja que estaba con él se acerca hasta la casilla para ver, porque quizá le extrañaba su ausencia, y al notar que no estaba ahí, acude al Casino a ver si tomó otro rumbo para ir al baño, pero no lo encuentran»."
      },
      {
        "title": "Detalles de la Casilla y Circunstancias"
      },
      {
        "para1": "En el interior de la casilla «hay una especie de pileta, de más o menos de un 1.60 metros de diámetro, y unos 2 metros de profundidad que está cargada con agua servida, y tiene un pequeño ventiluz», según explicó el funcionario policial. Se desconoce si el portón de acceso al lugar se encontraba cerrado bajo llave o con candado, o si el joven forzó la entrada."
      },
      {
        "title": "Acciones de Rescate"
      },
      {
        "para1": "Bomberos Voluntarios trabajaron desde la madrugada en el lugar, para desagotar el pozo que había en el interior, aunque hasta este mediodía no habían podido recuperar el cuerpo. En paralelo, los uniformados buscaron comunicarse con los padres del joven, que cursaba tercer año de la carrera de Comunicación Social en la Universidad Nacional del Nordeste."
      }
    ]
  }
  
  ,
  {
    "id": 13,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/05-foto-50-ilustrativa.jpg",
    "category": "ECONOMÍA",
    "title": "Internet: los principales aglomerados correntinos cerca de la cobertura total",
    "date": "24 Nov - 2024",
    "desc": [
      {
        "para1": "Según los datos recopilados por la Encuesta de Calidad de Vida que realiza el Instituto Nacional de Estadísticas y Censos (Indec), en el aglomerado de Corrientes, el 95,4 por ciento de los hogares poseen algún tipo de conexión a Internet, mientras que en Goya este valor es del 88,4."
      },
      {
        "para2": "En cuanto al tipo de conexión, en Corrientes, el 73,8 por ciento de los hogares cuenta con conexión fija, mientras que en Goya este porcentaje es del 65,6. Por otro lado, el 93,9 por ciento de los hogares del aglomerado capitalino cuenta con conexión móvil, mientras que en la segunda ciudad de la provincia este porcentaje es del 85,8."
      },
      {
        "para3": "Al analizar la evolución del acceso a Internet en los aglomerados de Corrientes y Goya, se observa una constancia en cuanto al nivel que presentan los indicadores. En el primer semestre de 2024, Corrientes mantuvo el porcentaje de hogares con acceso a Internet, mientras que Goya presentó un incremento de 2,1 puntos porcentuales respecto al semestre anterior."
      }
    ],
    "details": [
      {
        "title": "Evolución por Tipo de Conexión"
      },
      {
        "para1": "Si se detalla la evolución por tipo de conexión, en el área de la Capital, las conexiones fijas crecieron 1,7 por ciento respecto al periodo anterior, mientras que en Goya el aumento fue del 3,2. Por su parte, las conexiones móviles disminuyeron 0,1 por ciento en la ciudad de Corrientes y sus alrededores, mientras que en Goya subieron 1,6."
      },
      {
        "title": "Total Provincial"
      },
      {
        "para1": "En cuanto a la provincia de Corrientes, según la encuesta a Proveedores del Servicio de Acceso a Internet se registraron 124.187 hogares con acceso a Internet fijo en junio de 2024, lo que la posiciona como la provincia con mayor cantidad de hogares con este tipo de acceso a Internet en el Nordeste Argentino (NEA). Además, llegó a 906.503 el número hogares con acceso a Internet móvil en el territorio correntino, lo que implica una variación interanual del 3,2 por ciento con respecto a junio de 2023."
      }
    ]
  }
  
  ,
  {
    "id": 14,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/07-foto-71-miguel-valenzuela.jpg",
    "category": "CULTURA",
    "title": "Altura y colorismo en la restauración del icónico mural de la Correntinidad",
    "date": "24 Nov - 2024",
    "desc": [
      {
        "para1": "Un grupo de artistas llevan adelante la restauración del icónico mural de la Correntinidad ubicado en la explanada del puente General Belgrano. Las tareas comenzaron a fines de agosto y se estima que finalicen a mediados de diciembre."
      },
      {
        "para2": "«Hemos retomado por iniciativa del Municipio, y a través de una empresa que es la que pone toda la logística», contó en diálogo con EL LIBERTADOR, José Kura, integrante del equipo de artistas a cargo de los trabajos de restauración."
      },
      {
        "para3": "El mural tiene una extensión de 325 metros lineales por 10 metros en la parte más alta. «Estamos a más de 200 metros ya concluidos, ponele 220 o 230 metros», indicó y añadió que «esto no es sólo a merced de una persona, sino que hay un equipo de trabajo donde están artistas jóvenes como Ailén ‘Chaco’, Guadalupe Morlio, Agustina Nadal, Marcos Kura, y obran de asistente Edid Martínez y Gabriel Kura»."
      }
    ],
    "details": [
      {
        "title": "Incorporación de Artistas Invitados"
      },
      {
        "para1": "Además, informó que se han incorporado de manera eventual artistas invitados como José Mizdraji y Virginia Schauvinhold. «En cada paño hay artistas invitados, son jóvenes y están haciendo sus primeras pinceladas, y gracias al equipo conformado hemos llegado a este metraje interesante del mural», afirmó."
      },
      {
        "title": "Plazos y Desafíos de la Restauración"
      },
      {
        "para1": "Kura anunció que las tareas de restauración estarían finalizadas a mediados de diciembre. «Antes de las fiestas estaríamos cerrando el mural de la Correntinidad. Es un mural que no se basa en hacer grandes figuras sobre un fondo, sino que es un mural que tiene imágenes muy complejas, con muchas diagonales, con un trabajo de colorista muy importante», explicó. Añadió que «todo esto por supuesto que requiere tiempo, mucha preparación, aparte la mayoría de la superficie del mural es en altura, también es un punto que no está a favor, porque tenés que subir, bajar, mirar de abajo lo que hacés, rectificar, es un trabajo que tiene su desafío»."
      },
      {
        "title": "Desafíos en el Proceso de Restauración"
      },
      {
        "para1": "En los procesos de restauración, Kura relató que «hay un sector muy grande, que es el que da hacia la Cuenca, que prácticamente se ha extinguido, todo lo que en algún momento fue pintado está borrado por el moho, la humedad, los rayos ultravioletas, y esa es la parte que nos toca ahora pintar de nuevo». Resaltó que los artistas cuentan con los equipos de seguridad y herramientas correspondientes: «En altura estamos con arnés, con protección por cualquier eventual caída, también trabajamos con extensores que son una especie de barras de dos o tres metros que en la punta le ponemos el pincel y vamos pintando porque hay momentos de mucha altura, y los tablones no están pegados a la pared, sino que están a 30, o 40 centímetros»."
      },
      {
        "title": "Contribuciones y Trayectoria de José Kura"
      },
      {
        "para1": "Se recuerda que José Kura es uno de los artistas a cargo de la restauración y partícipe de su realización. «Soy el más viejo, el más antiguo, fui uno de los primeros del grupo de Arte Ahora en aquel entonces en el 96 de las gestiones para la realización de este mural», recordó. Esta obra fue pintada por artistas locales entre 1996 y 1997, y resalta distintos pasajes de la historia y la cultura de Corrientes. «La parte que me parece la más completa, lograda, que tiene como más mensaje y cosas muralísticas, es la parte central donde hay un enorme Cristo y está la Virgen de la Merced, San Luisito y hay una peregrinación. Creo que ese paño es no sólo el más grande, sino un paño muy significativo», comentó."
      },
      {
        "title": "Visiones de Futuro y Expansión Internacional"
      },
      {
        "para1": "¿Qué es lo que viene?",
        "para2": "-Lo que viene es seguir trabajando muchísimo en esto de dar a conocer lo que tiene Corrientes para ofrecer y yo creo que un poco es, a ver, yo de profesión soy licenciada en Relaciones Internacionales y me dediqué a eso hasta que fui madre hace casi seis años y eso te hace volver de vuelta, por lo menos en mi caso y en mi profesión, volver a lo sencillo, a lo esencial, que a veces es mucho más valioso que todo el ruido que por ahí te ofrece el mundo o la vorágine en la que vivimos, ¿no? Entonces se viene esto de volver a poner en valor lo pequeño, lo artesanal, las horas de trabajo que hay detrás de cada tejido, las dos ofrecemos piezas únicas, vos comprás algo en Weschenfeller y no hay otro igual, puede tener el mismo diseño pero nunca es el mismo color en el caso nuestro, ¿no?"
      },
      {
        "para3": "Se viene el compromiso con Pequeños Pasos que Flor les va a poder contar un poco mejor, pero sobre todo esto de tomar conciencia de nuestra cultura, de nuestra herencia, de nuestro valor. Segundo paso valorarlo y tercero seguir dándolo a conocer. Mauo también llegó a Estados Unidos, a Canadá. Que no sea un producto que lo compré en Argentina y lo traje, sino que podamos llevar allá, como el mate, ¿no? Que uno anda con el mate y todos se preguntan ¿qué es?, bueno, que cuando vean nuestras cosas verdaderamente puedan apreciar y valorar todo lo que hay detrás."
      }
    ]
  }
  
  ,
  {
    "id": 15,
    "cover": "https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/4R7A2184.jpg",
    "category": "METEOROLOGÍA",
    "title": "Corrientes: el calor agobia con una sensación térmica que supera los 40º",
    "date": "24 Nov - 2024",
    "desc": [
      {
        "para1": "La ciudad de Corrientes “arde” este mediodía de domingo, con una sensación térmica de 41,1ºC, sin probabilidad de precipitaciones, de acuerdo al Servicio Meteorológico Nacional (SMN). Hoy la Capital ingresó en el ranking de las ciudades más calurosas del país. Se espera una semana calurosa."
      },
      {
        "para2": "Así lo advirtió el organismo nacional en su pronóstico extendido. Las temperaturas mínimas se mantendrán por encima de los 20ºC y las máximas superarán los 30ºC. Quizá algunos chaparrones previstos para la tarde de este lunes, ayuden a mitigar el calor."
      },
      {
        "para3": "Se espera asimismo que desde el martes y hasta el viernes ocurran tormentas aisladas y chaparrones en la zona, según el SMN."
      }
    ],
    "details": [
      {
        "title": "Pronóstico del Servicio Meteorológico Nacional"
      },
      {
        "para1": "El Servicio Meteorológico Nacional (SMN) ha emitido un pronóstico extendido que prevé una semana calurosa en Corrientes. Las temperaturas mínimas se mantendrán por encima de los 20ºC y las máximas superarán los 30ºC. Se anticipa la posibilidad de chaparrones el lunes por la tarde que podrían ayudar a mitigar las altas temperaturas."
      },
      {
        "title": "Expectativas de Tormentas y Chaparrones"
      },
      {
        "para1": "Desde el martes hasta el viernes, el SMN espera que ocurran tormentas aisladas y chaparrones en la zona, lo que podría ofrecer cierto alivio al calor intenso que se vive en la ciudad."
      },
      {
        "title": "Recomendaciones del Ministerio de Salud"
      },
      {
        "para1": "El Ministerio de Salud ha emitido una serie de recomendaciones para enfrentar las altas temperaturas. Entre ellas se incluyen:"
      },
      {
        "para2": "• Aumentar el consumo de agua sin esperar a tener sed para mantener una hidratación adecuada."
      },
      {
        "para3": "• No exponerse al sol en exceso, ni en horas centrales del día (entre las 10 y las 16 horas)."
      },
      {
        "para3": "• Prestar atención a los bebés, niños y niñas y a las personas mayores."
      },
      {
        "para3": "• Evitar las bebidas con cafeína, con alcohol o muy azucaradas."
      },
      {
        "para3": "• Evitar comidas muy abundantes."
      },
      {
        "para3": "• Ingerir verduras y frutas."
      },
      {
        "para3": "• Reducir la actividad física."
      },
      {
        "para3": "• Usar ropa ligera, holgada y de colores claros; sombrero, anteojos oscuros."
      },
      {
        "para3": "• Permanecer en espacios ventilados o acondicionados."
      },
      {
        "para3": "• Recordar que no existe un tratamiento farmacológico contra el golpe de calor y sólo los métodos clásicos, citados arriba, pueden prevenirlo y contrarrestarlo."
      }
    ]
  }
  
  ,
  {
    id: 16,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/whatsapp_image_2024-11-05_at_11_16_14_1_.jpeg',
    category: "POLÍTICA",
    title: "Tassano recibió al cónsul de Paraguay: consolidaron lazos y trazaron agenda",
    date: "5 Nov - 2024",
    desc: [
      {
        para1: "5/11/2024 - En el Palacio Municipal, el intendente Eduardo Tassano recibió al cónsul de Paraguay, Fabio López Riveros, en una reunión que fortaleció los lazos históricos y presentes entre Corrientes y el país vecino."
      },
      {
        para2: "López Riveros, cuya jurisdicción abarca Corrientes y Chaco con sede en Resistencia, presentó oficialmente sus saludos protocolares en el Municipio correntino."
      },
      {
        para3: "Durante la reunión, Tassano y el cónsul discutieron sobre iniciativas para fortalecer la integración regional y compartieron presentes en señal de amistad."
      }
    ],
    details: [
      {
        title: "Consolidando lazos y creando puentes"
      },
      {
        para1: "Tassano valoró la visita de un país querido como Paraguay y resaltó la importancia de que Corrientes se consolide como un referente del norte argentino para articular acciones turísticas y diplomáticas."
      },
      {
        title: "Hermandad y agenda común"
      },
      {
        para2: "El cónsul López Riveros agradeció el recibimiento y señaló que ambos trazaron una agenda común para promover la integración mediante el comercio, cultura y turismo entre ambos países."
      }
    ]
  }
  ,
  {
    id: 17,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/11/o_1711625620.jpg',
    category: "JUSTICIA",
    title: "Poder Judicial de Corrientes definió el periodo de feria",
    date: "5 Nov - 2024",
    desc: [
      {
        para1: "5/11/2024 - El Poder Judicial de Corrientes estableció el periodo de feria judicial de verano mediante el Acuerdo de Ministros N°32/24."
      },
      {
        para2: "El último día laborable será el lunes 23 de diciembre de 2024, ya que el martes 24 se decretó feriado judicial."
      },
      {
        para3: "La feria se extenderá desde el jueves 26 de diciembre de 2024 hasta el viernes 31 de enero de 2025."
      }
    ],
    details: [
      {
        title: "Feriados judiciales adicionales"
      },
      {
        para1: "Se declaró feriado judicial los días 24 y 31 de diciembre por las festividades de Nochebuena y Fin de Año, de modo que el personal judicial podrá disfrutar de estos días en familia."
      }
    ]
  }
  ,


  // HASTA ACA SE VEN DE ENTRADA, LAS DE ABAJO HAY QUE MOVER EL SLIDER PARA VERLAS.
  {
    id: 18,
    category: "POLICIALES",
    title: "Libres: recuperan el dinero robado de un comercio por Ruta 117",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.diarioepoca.com/content/bucket/5/1320445w762h739c.jpg.webp",
    desc: [
      {
        para1: "Efectivos de la Comisaría Primera de Paso de los Libres realizaron ayer un allanamiento y lograron recuperar la totalidad del botín en efectivo que delincuentes robaron de un parador ubicado a la vera de la Ruta Nacional N°117. Hay dos hombres detenidos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Según la información proporcionada por la Policía, el hecho ocurrió días atrás y durante el fin de semana se demoró a los dos maleantes que habían perpetrado el ilícito, pero faltaba ubicar el lugar donde ocultaban el dinero. Los trabajos consistieron en reunir toda la información posible, que también constó de imágenes de cámaras de seguridad de la zona y con lo que pudieron identificar a los delincuentes." },
      {
        quote: "Por ello, el sábado elementos de la sección Investigaciones de la Comisaría Primera, con orden de allanamiento de la Unidad Fiscal de Investigaciones de Paso de los Libres, requisaron una finca ubicada en la quinta sección Palmar, Chacra 250, donde finalmente lograron dar con la totalidad del dinero en efectivo, alrededor de $450.000 que había sido robado del parador Don Tolo",
      },
      {
        para2: "Tanto los dos detenidos como el dinero fueron puestos a disposición de la Justicia y la investigación continúa para descartar la participación de más personas en el hecho.",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 19,
    category: "TECNOLOGIA",
    title: "Cómo la Inteligencia Artificial está cambiando la forma de buscar vida extraterrestre",
    date: "17. Septiembre 2023",
    comments: 0,
    cover: "https://www.infobae.com/new-resizer/MQrbURODdl9ufluwK5Mch7FjW4c=/992x558/filters:format(webp):quality(85)/cloudfront-us-east-1.images.arcpublishing.com/infobae/ELC3ESJ3AZHKNJETOU2YQT4I7Y.png",
    desc: [
      {
        para1: "Tras el lanzamiento de dos telescopios con el objetivo de encontrar planetas habitables, y con un total de 5.496 exoplanetas confirmados y 9.820 más en espera de este aval, la carrera por caracterizar estos mundos distantes y evaluar su habitabilidad impulsa a la ciencia hacia nuevos límites",
      },
      {
        para2: ".",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En los últimos dos años y medio, se han enviado al espacio dos telescopios de próxima generación: el Espacial James Webb (JWST) de la NASA y el Observatorio Euclid de la ESA. Antes de que termine la década, se les unirán el Telescopio Espacial Romano Nancy Grace (RST), el Espectrofotómetro para la Historia del Universo, la Época de la Reionización y el Explorador de Hielos (SPHEREx) de la NASA, y el Tránsito y Oscilaciones Planetarias de la ESA (PLATO), entre otros." },
      {
        quote: "Estos observatorios se basarán en óptica e instrumentos avanzados para ayudar en la búsqueda y caracterización de exoplanetas con el objetivo final de encontrar planetas habitables. Junto con las misiones aún operativas, estos observatorios recopilarán volúmenes masivos de datos espectroscópicos de alta resolución.",
      },
      {
        para2: "lasificar esta información requerirá técnicas de aprendizaje automático de vanguardia para buscar indicios de vida y procesos biológicos (también conocidos como biofirmas). En un artículo reciente, un equipo de científicos del Instituto de Teoría Fundamental de la Universidad de Florida (UF-IFL) recomendó que futuros estudios utilicen el aprendizaje automático para buscar anomalías en los espectros, que podrían revelar firmas químicas inusuales y biológicas desconocidas.",
      },
      {
        para3: "Una preimpresión de su artículo se publicó en arXiv y está siendo revisada para su publicación en Astrophysical Journal. Allí los especialistas explicaron, que la “vida” sigue siendo una cuestión abierta para los científicos, y sería ventajoso ampliar el alcance de nuestra búsqueda.",
      },
    ],
  },
  {
    id: 20,
    category: "DEPORTES",
    title: "Boca Unidos logró salir airoso en un partido cambiante y emotivo",
    date: "30. Septiembre 2023",
    comments: 0,
    cover: "https://pxc.cdn.ellitoral.com.ar/litoral/092023/1695000729381.webp?cw=770&ch=440&extw=jpg",
    desc: [
      {
        para1: "El equipo correntino revirtió un resultado adverso para ganarle de local a Crucero del Norte por 2 a 1. Mainero puso en ventaja al Colectivero, pero Gabi Morales, de penal, y Maximiliano Solari sobre el final anotaron para el Aurirrojo.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Boca Unidos golpeó último y se quedó con tres valiosos  puntos al ganarle ayer de local a Crucero del Norte por 2 a 1 para afianzarse en la tercera posición de la zona 4, en un encuentro cambiante y emotivo jugado por la fecha 31 del torneo Federal “A”." },
      {
        quote: "El Aurirrojo regaló el primer tiempo, lapso en el que el Colectivero se puso adelante en el marcador frente a un equipo local que no daba pie con bola. En el complemento, Boca Unidos cambió la imagen, empató a través de un penal, jugada en la que quedó con un hombre más, pero en su afán por ir a buscar enceguecidamente el triunfo se descuidó, cometió errores defensivos en los que su rival le perdonó la vida, hasta que llegó al desequilibrio en el final. El inicio del encuentro encontró a un Crucero del Norte enchufado que salió a jugar el partido como una verdadera final frente a un Boca Unidos que estaba dormido y deambulaba por la cancha sin poder hacer tres pases seguidos. La visita avisó al minuto, con un tiro libre que Nicolás Portillo bajó de cabeza para Alejo Mainero, que fue trabado por Joaquín Livera. ",
      },
      {
        para2: "El gol del Colectivero no  tardó en llegar. Walter Figueroa envió un centro pasado que Gastón Torres devolvió de cabeza hacia el medio. El balón le quedó otra vez a Mainero, que pifió el primer remate, pero inmediatamente se rehizo para enviar el balón a la red.",
      },
      {
        para3: "Ahora el equipo correntino tendrá que ir el próximo domingo a jugar “otra final” en Salta, frente a Juventud Antoniana, que al igual que Crucero del Norte, se juega todas las fechas la permanencia, y luego quedará libre. Pero para todavía falta, por ahora es todo alegría.",
      },
    ],
  },
  {
    id: 21,
    category: "POLITICA",
    title: "Ya se debate en Diputados la modificación del impuesto a las ganancias",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.mdzol.com/u/fotografias/m/2023/9/15/f1280x720-1476969_1608644_5050.jpeg",
    desc: [
      {
        para1: "En una reunión informativa, la Comisión de Presupuesto y Hacienda, presidida por el diputado del Frente de Todos Carlos Heller, la Cámara de Diputados de la Nación comenzó a analizar el proyecto de Ley, enviado el pasado 12 de septiembre, por el cual se deja sin efecto el impuesto a las ganancias que recae sobre los ingresos del trabajo personal ejecutado en relación de dependencia, jubilaciones y pensiones y, por el otro, incorpora en la ley del mencionado gravamen, un régimen cedular dirigido, exclusivamente, a los mayores ingresos derivados de aquellas rentas.",
      },
      {
        para2: "En primer lugar, la subsecretaria de Ingresos Públicos, Claudia Balestrini, defendió el proyecto, y en ese sentido explicó: “Lo que vinimos a hacer ahora es algo superador y tiene que ver con atender a las rentas generadas en relación de dependencia (cuarta categoría)”.",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Además, la funcionaria brindó detalles de la norma: “Las rentas originadas por empleadas y empleados en relación de dependencia van a empezar a tributar bajo un esquema cedular”. “Consiste en establecer un mínimo no imponible de 180 salarios mínimos, vitales y móviles. De superar ese importe el trabajador va a tributar por el excedente”, puntualizó." },
      {
        quote: "En la misma línea que su preopinante, la ministra de Trabajo, Kelly Olmos, aseguró que “el salario no es ganancia”, al tiempo que indicó que “el proyecto establece la eliminación de la cuarta categoría del impuesto a las ganancias y su reemplazo por un impuesto a los altos ingresos”.",
      },
      {
        para2: "“Este proyecto está destinado a una mejor organización del trabajo argentino para mejorar las condiciones de productividad y redistribución que nuestro país necesita”, ponderó la responsable de la cartera de Trabajo.",
      },
      {
        para3: "Por otra parte, el titular de la Administrador Federal de Ingresos Públicos (AFIP), Carlos Castagneto, afirmó que “es un proyecto que deja sin efecto el impuesto a las ganancias que recae sobre los ingresos de trabajo personal, ejecutado en relación de dependencia, jubilaciones y pensiones” y que -añadió-, además, “incorpora un régimen cedular dirigido exclusivamente a los mayores ingresos derivado de aquellas rentas”.",
      },
    ],
  },
  {
    id: 22,
    category: "POLICIALES",
    title: "Arrojaron 11 kilos de marihuana al río y fueron detenidos",
    date: "24. Noviembre 2023",
    comments: 0,
    cover: "https://pxc.cdn.ellitoral.com.ar/litoral/112023/1700825192298.webp?cw=770&ch=440&extw=jpg",
    desc: [
      {
        para1: "El operativo fue realizado por la Policía.",
      },
      {
        para2: "La Policía de Corrientes informó este viernes que secuestró  11 kilos de marihuana y detuvo a dos hombres  en la localidad de Itatí. ",
      },
      {
        para3: "El operativo se realizó el jueves cuando observaron que dos ocupantes de una canoa  arrojaron un paquete al rio Paraná a la altura de una isla de la zona.",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En ese momento comenzó el procedimiento y se logró detener a dos sujetos, uno de ellos de nacionalidad paraguaya, señaló la fuerza. " },
      {
        quote: "En total, se incautó  11,225 kilos.",
      },
      {
        para2: "La canoa, la droga y los dos involucrados quedaron a disposición de la Justicia. ",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 23,
    category: "ECONOMIA",
    title: "Pagos a jubilados, pensionados, AUH y otras prestaciones del lunes 18",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/04/l_1693826424.jpg",
    desc: [
      {
        para1: "La Administración Nacional de la Seguridad Social (ANSES) pagará este entre el lunes 18 de septiembre de 2023 jubilaciones, pensiones, Asignación Universal por Hijo (AUH), Asignación por Embarazo (AUE), Asignaciones Familiares, de Pago Único (Matrimonio, Nacimiento y Adopción).",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "Desde este mes las jubilaciones, pensiones y asignaciones se cobrarán con el aumento del 23,29 por ciento por la Ley de Movilidad." },
      {
        quote: "En tanto, todos los jubilados y pensionados que perciben el haber mínimo recibirán la primera cuota del refuerzo de 37 mil pesos (la segunda en octubre y la tercera en noviembre por el mismo monto), por lo que ninguno de ellos cobrará menos de 124.460 pesos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 24,
    category: "SALUD",
    title: "Más de 4 mil mujeres se hicieron la prueba de parches mamarios",
    date: "18. Septiembre 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/18/l_1695032518.jpg",
    desc: [
      {
        para1: "Ahora, este dispositivo estará disponible en los Centros de Atención Primaria de la Salud (CAPS) de la capital. Hasta el momento entre los resultados, se mostró un 7.11% de significatividad entre el total de mujeres que se realizaron la prueba que dura 15 minutos.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "El Ministerio de Salud Pública de la Provincia de Corrientes, a cargo de Ricardo Cardozo, lleva adelante la campaña para el diagnóstico precoz del Cáncer de Mama: “Ruta de la Salud Mamaria” a partir de la colocación de PADS o Parches Mamarios. Este nuevo método de diagnóstico precoz impulsado por el gobernador Gustavo Valdés detecta alteraciones relacionadas con este problema de salud, estaba disponible hasta el momento en el interior y algunos centros de salud de capital pero ahora se comenzará a aplicar desde esta semana en los 17 CAPS de la capital provincial." },
      {
        quote: "Los PADS o Parches Mamarios son dispositivos con una innovadora tecnología, que consiste en microsensores que captan las diferencias de temperatura de las mamas, siguiendo principios físicos termodinámicos.",
      },
      {
        para2: "“Desde hace tres meses, a partir de junio se lanzó la campaña, empezamos con las pruebas de los parches mamarios en toda la provincia. Iniciamos en el interior; en algunos centros de capital y ahora en la capital en los CAPS. Tenemos más de 4 mil pruebas de parches realizados a lo largo de toda la provincia y la semana siguiente se avanzará en los CAPS de la capital. Seguramente ahí aumentará la cantidad de pacientes que se colocan”, dijo la coordinadora de la Red Provincial de Cáncer de Mama, Karina Maidana.",
      },
      {
        para3: "",
      },
    ],
  },
  {
    id: 25,
    category: "CORRIENTES",
    title: "Gendarmería halló 16 vizcachas faenadas en Curuzú Cuatiá",
    date: "18. Julio 2023",
    comments: 0,
    cover: "https://www.corrienteshoy.com/galeria/fotos/2023/09/18/l_1695044549.jpg",
    desc: [
      {
        para1: "En horas de la madrugada del sábado, una de las patrullas transitaba en cercanías al acceso a la localidad y procedieron al control físico y documentológico de un vehículo los cuales se dirigían hacia Rafaela, Santa Fe. Parte de la carne secuestrada ya había sido cocida.",
      },
      {
        para2: "",
      },
      {
        para3: "",
      },
    ],
    details: [
      {
        title: "",
      },
      { para1: "En horas de la madrugada, y en momentos que la una de las patrullas transitaba en cercanías al acceso a la localidad de Curuzú Cuatiá, procedieron al control físico y documentológico de un vehículo, ocupado por dos masculinos mayores de edad, los cuales se dirigían hacia Rafaela, Santa Fe." },
      {
        quote: "Al momento de solicitar la documentación del rodado, los uniformados pudieron ver una un arma de fuego y varias bolsas plásticas, motivando la requisa del mismo. Producto de la inspección, se hallaron una carabina, 50 municiones, 16 vizcachas faenadas y sin cuero, así como tres bolsas más que contenían carne de la misma especie pero que ya había atravesado un proceso de cocción.",
      },
      {
        para2: "Se dio intervención a la Fiscalía Rural y Ambiental de Mercedes, así como a la Policía Rural y Ambiental de Curuzú Cuatiá, procediendo al secuestro del arma de fuego y los animales faenados, siendo desnaturalizados bajo acta de estilo.",
      },
      {
        para3: "",
      },
    ],
  },
]
export const ppost = [
  {
    id: 26,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/723fe800-d506-4eda-86ab-ba0d67cecc33.jpg',
    category: "CULTURA",
    title: "Presentaron la 14 edición del Taragüi Rock: las sorpresas para el público",
    date: "28 Oct - 2024",
    desc: [
      {
        para1: "28/10/2024 - El gobernador de Corrientes, Gustavo Valdés, y la presidenta del Instituto de Cultura, Beatriz Kunin, anunciaron la 14ª edición del Festival Taragüi Rock, que se celebrará el 9 y 10 de noviembre en el Corsódromo Nolo Alías de la Capital."
      },
      {
        para2: "Kunin expresó su entusiasmo por este festival, consolidado a nivel local, regional y nacional, y destacó novedades como un área de juegos para niños y una zona especial para personas con capacidades diferentes."
      },
      {
        para3: "El predio en Ruta 12 km 1028 tendrá dos escenarios, capacidad para 12,000 personas, 1,900 lugares de estacionamiento y accesos para la prensa y artistas."
      }
    ],
    details: [
      {
        title: "Transporte gratuito y entradas"
      },
      {
        para1: "Se coordinó un servicio gratuito de colectivos, posiblemente desde el Parque Mitre, para facilitar el traslado de asistentes al Corsódromo. Las entradas se venden con un 20% de descuento online y posibilidad de pago en tres cuotas con tarjetas del Banco de Corrientes."
      },
      {
        title: "Declaraciones de autoridades"
      },
      {
        para2: "El gobernador Valdés destacó el valor del festival para la cultura correntina, señalando que permite la interacción con otros géneros como el chamamé. La conferencia contó con la presencia del vicegobernador Pedro Braillard Poccard y varios funcionarios."
      }
    ]
  }
  ,
  {
    id: 27,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/821180-gustavo-20vald-c3-a9s-na.jpg',
    category: "DEPORTES",
    title: "Finalmente, Corrientes estará en los Juegos Evita 2024",
    date: "22 Oct - 2024",
    desc: [
      {
        para1: "22/10/2024 - El gobernador Gustavo Valdés anunció que Corrientes participará en los Juegos Nacionales Evita 2024, financiando los gastos con recursos provinciales para que 350 atletas puedan representar a la provincia."
      },
      {
        para2: "Valdés destacó la importancia del deporte en el desarrollo juvenil, afirmando que «el deporte es fundamental para el desarrollo de los jóvenes»."
      }
    ],
    details: [
      {
        title: "Decisión previa de no participar"
      },
      {
        para1: "El secretario de Deportes, Jorge Terrile, había informado previamente que Corrientes no participaría debido a desacuerdos con los criterios organizativos de Nación. Aclaró que la decisión no fue por falta de presupuesto, sino por diferencias en el enfoque."
      },
      {
        para2: "Terrile había expresado que la provincia ya cubría hasta el 70% de los gastos para asistir y no consideraba justo que los ajustes afecten a los deportistas. Mencionó que solo 17 de las 24 provincias confirmaron su participación este año."
      },
      {
        title: "Detalles del evento"
      },
      {
        para3: "Los Juegos Nacionales Evita 2024 se realizarán en Mar del Plata del 4 al 9 de noviembre y contarán con competencias en 36 disciplinas."
      }
    ]
  }
  ,
  {
    id: 28,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/05-FOTO-50-Gentileza-5.jpg',
    category: "ECONOMÍA",
    title: "Valdés llamó a superar el debate parlamentario y salir al territorio",
    date: "22 Oct - 2024",
    desc: [
      {
        para1: "22/10/2024 - Durante la apertura de la Exposición Rural de Ituzaingó, el gobernador Gustavo Valdés instó a impulsar políticas que promuevan el crecimiento económico en Corrientes, destacando la calidad del ganado bovino e invitando al sector privado a invertir en la industria."
      },
      {
        para2: "Valdés subrayó la necesidad de invertir en mejoras genéticas y en infraestructura rural para potenciar la producción ganadera y combatir la pobreza, enfatizando que el desarrollo debe trascender los debates del Congreso."
      }
    ],
    details: [
      {
        title: "Impulso a la ganadería y la industria"
      },
      {
        para1: "El Gobernador resaltó la importancia de la calidad genética del ganado correntino y expresó su expectativa de que los productores obtengan buenos precios. Corrientes cuenta con 26,000 ganaderos activos y busca mejorar la eficiencia a través del Plan Pastura."
      },
      {
        title: "Fortalecimiento de la infraestructura rural"
      },
      {
        para1: "Valdés enfatizó la necesidad de mejorar caminos rurales y apoyar a organizaciones comunitarias, destacando que el desarrollo debe orientarse hacia la producción e industrialización como vía para enfrentar la pobreza."
      },
      {
        title: "Incremento de la inversión privada"
      },
      {
        para1: "El mandatario invitó al sector privado a intensificar su participación en la industria provincial, destacando la potencialidad de Corrientes en la ganadería y el desarrollo rural. La nueva sede inaugurada fue descrita como símbolo del progreso regional."
      },
      {
        title: "Respaldo financiero y desarrollo productivo"
      },
      {
        para1: "Valdés anunció un apoyo de 3.000 millones de pesos para proyectos productivos y resaltó la importancia de la producción de búfalos en Caá Catí, con 70,000 cabezas, y el interés de China en productos bubalinos."
      },
      {
        title: "Conservación de la tradición ganadera"
      },
      {
        para1: "El Gobernador destacó la relevancia de mantener la tradición ganadera correntina, orientando la producción local hacia un crecimiento sostenido a largo plazo. La ubicación estratégica de Ituzaingó busca posicionar a Corrientes como referente en genética y producción."
      }
    ]
  }
  ,
  {
    id: 29,
    cover: 'https://www.diarioellibertador.com.ar/wp-content/uploads/2024/10/TERESA.jpg',
    category: "POLÍTICA",
    title: "La interventora del PJ correntino criticó a Kicillof",
    date: "17 Oct - 2024",
    desc: [
      {
        para1: "17/10/2024 - La senadora bonaerense María Teresa García criticó en Radio 750 a algunos dirigentes peronistas que han «embarrado» las internas del Partido Justicialista (PJ), señalando especialmente al gobernador Axel Kicillof y al mandatario riojano Ricardo Quintela."
      },
      {
        para2: "El PJ realizará elecciones el 17 de noviembre para definir su nueva conducción, en medio de divisiones pese al llamado de unidad de Cristina Fernández de Kirchner."
      }
    ],
    details: [
      {
        title: "Críticas a Kicillof y Quintela"
      },
      {
        para1: "García, exministra de Gobierno de Kicillof, cuestionó la falta de pronunciamiento del gobernador sobre las internas del PJ. Afirmó que alguien tan cercano a Cristina debería haber expresado su posición."
      },
      {
        para2: "La senadora acusó a Quintela de entorpecer el proceso interno, destacando que, a pesar de su promesa de retirar su candidatura si Cristina se presentaba, esto no ocurrió."
      },
      {
        title: "Clima de conflicto"
      },
      {
        para3: "García advirtió sobre un ambiente de odio y enojo en el peronismo y enfatizó que el partido necesita un rumbo claro. Concluyó pidiendo reflexión a Kicillof, señalando que algunas personas de su entorno no serían la mejor influencia."
      }
    ]
  }
  
  
]
export const lifestyle = [
  {
    id: 30,
    category: "INTERIOR",
    title: "Amplio operativo para la celebración del Gauchito Gil.",
    date: "05. Enero 2024",
    comments: 0,
    cover: "https://www.diarioepoca.com/content/bucket/0/1340360w762h476c.jpg.webp",
  },
  {
    id: 31,
    category: "CORRIENTES",
    title: "Día picante en Diputados: 'Empezó mal el año', advirtió Jorge Romero",
    date: "05. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/6/1340346w380h179c.jpg.webp",
  },
  {
    id: 32,
    category: "CORRIENTES",
    title: "Largas filas en plena Ruta 12 para comprar una garrafa de gas a $5.000",
    date: "03. Enero 2024",
    cover: "https://www.diarioepoca.com/content/bucket/2/1340352w762h564c.jpg.webp",
  },
  {
    id: 33,
    category: "CORRIENTES",
    title: "La Provincia compró el predio de la tabacalera que cerró en Goya",
    date: "17. Septiembre 2023",
    cover: "https://www.nortecorrientes.com/content/bucket/8/369968w380h220c.jpg.webp",
  },
]
export const tpost = [
  {
    id: 34,
    title: "Radio Sudamericana",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/sudamericana-corrientes.png",
    href: "https://envivo.radiosudamericana.com/inc.popup-reproductor.php?popup=true"
  },
  {
    id: 35,
    title: "Radio Dos",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/radio-dos-corrientes.png",
    href: "https://www.radiodos.com.ar/radio.php"
  },
  {
    id: 36,
    title: "LT7",
    cover: "https://cdn.webrad.io/images/logos/radioarg-com/lt7-900-corriente.png",
    href: "https://www.lt7noticias.com/radio.php"
  },
]
export const gallery = [
  {
    cover: "../images/gallery/g1.jpg",
  },
  {
    cover: "../images/gallery/g2.jpg",
  },
  {
    cover: "../images/gallery/g3.jpg",
  },
  {
    cover: "../images/gallery/g4.jpg",
  },
  {
    cover: "../images/gallery/g5.jpg",
  },
]
export const discover = [
  {
    title: "gameñss3a22aaaaaaadasa2s",
    cover: "../images/discover/d1.jpg",
  },
  {
    title: "DEPORTES",
    cover: "../images/discover/d2.jpg",
  },
  {
    title: "humaouaaaaAaArr",
    cover: "../images/discover/d3.jpg",
  },
  {
    title: "gadgets",
    cover: "../images/discover/d4.jpg",
  },
  {
    title: "movieees",
    cover: "../images/discover/d5.jpg",
  },
  {
    title: "niten3aa2asdkik25aaaaaaassadaaaao!",
    cover: "../images/discover/d6.jpg",
  },
]

